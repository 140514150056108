.button{
    display: flex !important;
    border-radius: 25px !important;
   // padding: 12px 32px !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: 'Fira Sans' !important;
   // font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
   // line-height: 24px !important; /* 133.333% */
    text-transform: capitalize !important;
}

.primary{
    background-color: #F93 !important;
    color: #000 !important;
}

.secondary{
    background-color: #33196B;
    color: #FFFFFF;
}

.custom{
    color: #000 !important;
    background-color: #FFC200 !important;
    text-align: center !important;
//    padding: 12px 104px !important;
//    width: 100% !important;
}
.yes{
    background-color: #3AB54A !important;
    color: #FFFFFF !important;
}
.no{
    background-color: #FE0000 !important;
    color: #FFFFFF !important;
}
.outlined{
    border-color: #F93 !important;
    color: #000 !important;;
}