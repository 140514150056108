.formContainer {
    padding: 80px 100px;

    @media (max-width: 600px) {
        padding: 80px 10px;
    }
}

.categoryHeading {
    font-size: xx-large;
    font-weight: bold;
    color: #053D70;
}

.centerAlignedContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sectionImage {
    width: 50%;
    height: auto;
    padding-left: 20px;

    @media (max-width: 600px) {
        width: 95%;
    }
}

.articleImage {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0px 20px;
    border-radius: 6px;
    filter: brightness(80%)
}

.articleTitle {
    font-size: 25px;
    font-family: 'Kiona';
    font-weight: bolder;
    line-height: 19.2px;
    color: #FFC200;
    padding: 28px 10px 10px 10px;
    background-color: #053D70;
    width: 80%;
    position: absolute;
    padding: 20px;
    opacity: 80%;
    @media (max-width: 760px) {
        font-size: 18px;
    }
}

.articleTitleSub {
    font-size: 25px;
    font-weight: bolder;
    line-height: 19.2px;
    color: white;
    padding: 28px 10px 10px 10px;
    width: 80%;
    position: absolute;
    padding: 20px;
    opacity: 80%;
    margin-top: 100px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    @media (max-width: 760px) {
        font-size: 14px;
        margin-top: 100px;
    }
}

.articleHeading {
    border-left: 5px solid yellow;
    font-size: 25px;
    font-family: 'Kiona';
    font-weight: bolder;
    line-height: 19.2px;
    color: #053D70;
    margin-top: 28px;
    margin-left: 20px;
    padding: 0px 20px 10px 10px;
    @media (max-width: 760px) {
        font-size: 16px;
    }
}

.articleParagraph {
    padding: 10px 20px;
    @media (max-width: 760px) {
        font-size: 12px;
    }
}

.articleList {
    padding: 0px 10px 0px 18px
}

.error {
    color: red;
    padding: 0px 0px 10px 0px;
}

.bootonButtons {
    margin-top: 30px;
}

.table {
    min-width: 650px;
}

.table th {
    background-color: #f0f0f0;
}

.tableContainer {
    padding-top: 20px;
}

.pagination {
    margin-top: 20px;
    justify-content: center;
    display: flex;
}

.pagination button {
    padding: 8px 16px;
    margin-right: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.pagination button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}

.addArticles {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.addArticles button {
    padding: 8px 16px;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #ffe600;
}

.addArticles button+button {
    margin-left: 10px;
    /* Add spacing between buttons */
}

.addArticles button:hover {
    background-color: #0056b3;
    color: white;
    /* Change to your desired button hover color */
}