.mainBody{
    
}
.banner{
    background-color: #FFC200;
    background-image: url('../../assets/Banner_bg.svg');
    background-position: bottom;
    background-repeat: no-repeat;
}
.signUpHeading{
    color: #FFC200 !important;
    font-family: 'Kiona';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.label{
    color: #FFF;
    font-family: 'Kiona';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.inputBox{
    border-radius: 8px;
    border: 1px solid #888;
    background: #FFF;
    outline-style: none;
    width: 100%;
    padding-left: 5px;
    font-family: 'Fira Sans';
    ::placeholder{
        font-family: 'Fira Sans';
        color:#888
    }
}
.txtError{
    color: #FF0000;
    font-size: 12px;
    font-family: 'Fira Sans';
}
.showPassword{
    svg{
        color: black !important;
        margin-top: 0.5% !important;
        z-index: 1000 !important;
        position: absolute !important;
        right: 11% !important;
    }
}

.agreement{
    font-family: 'Fira Sans';
    color: #FFFFFF;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    
}
.separator {
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: -7px;

    .or-Style{
        color: #565556;
        font-size: 12px;
        font-weight: 400;
    }

    .line {
        border: 1px solid #565556;
        width: 145px;
        height: 1px;
    }

    span {
        padding: 0 1rem;
    }
}