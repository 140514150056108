.breadCrumbPrevious{
    // width: 3.5%;
    float:left;
    font-family: 'Fira Sans' !important;
    font-weight: 400 !important;
    letter-spacing: 0em;
    text-align: left;
    color: #888888;
    cursor: pointer;
}
.breadCrumbCurrent{
    font-family: 'Fira Sans' !important;
    font-weight: 400 !important;
    letter-spacing: 0em;
    text-align: left;
    color: #053D70;
}
.heading{
    font-family: 'Kiona' !important;
    font-weight: 400 !important;
    text-align: center;
    text-transform: capitalize;
    color:#053D70;
}

.underline{
    min-height: 5px;
    border-radius: 5px;
    margin-top: 10px !important;
    background-color: #FFC200;
}