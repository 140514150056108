.footer{
    bottom:0;
    position: fixed;
    width: 100%;
   .footerContainer{
    border-radius: 40px 40px 0px 0px;
    background-color: #053D70;
    width: 100%;
    .footerText{
        width: 100%;
        color: #FFFFFF;
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 600;
    }
   }
}