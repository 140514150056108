.inputBox{
    border-radius: 8px;
    border: 1px solid #888;
    background: #FFF;
    outline-style: none;
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    font-family: 'Fira Sans';
    // padding-bottom: 86px;
    text-transform: none !important;
    &::placeholder{
        font-family: 'Fira Sans';
        padding-bottom: 86px;
    }
}


.label{
    color: #053D70;
    font-family: Kiona;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;
}

.txtError{
    color: #FF0000;
    font-size: 12px;
    margin-bottom: 500px;
    font-family: 'Fira Sans';
    
}
