.signUpHeading{
    color: #FFC200 !important;
    font-family: 'Kiona';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pageDescription{
    font-family: 'Fira Sans';
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF
}

.subscriptionData{
    border-radius: 20px;
    border: 4px solid #FFC200;
    // padding: 0px;
    background-color: #FFFFFF;
}