@media screen and (max-width: 480px) {
  .landing-header-button {
    width: 7rem;
  }
  .landing-header-button .title-text {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 360px) {
  .landing-header-button {
    min-width: 56px;
    padding-left: 0;
    padding-right: 0;
    width: 5rem;
  }
  .landing-header-button .title-text {
    font-size: 0.85rem;
  }
}