.signUpHeading{
    color: #FFC200 !important;
    font-family: 'Kiona';
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.completeDescription{
    color: #FFF;

    font-family: 'Fira Sans';
    //font-size: 20px;
    font-style: normal;
    font-weight: 400;
   // line-height: 32px;
}